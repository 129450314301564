import { JOBS_PER_PAGE, routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { makeSlug } from '/machinery/makeSlug'
import { JobCard } from '/features/buildingBlocks/JobCard'
import { JobAlert } from '/features/pageOnly/jobs/JobAlert'
import { NoResultsCard } from '/features/pageOnly/jobs/NoResultsCard'
import { getJobLabels } from '/domain/getJobLabels'
import { Pagination } from '/features/buildingBlocks/Pagination'
import { mapJobData } from '/machinery/mapJobData'
import { RewardCalculatorCtaJobsOverview } from '/features/buildingBlocks/reward-calculator/RewardCalculatorCtaJobsOverview'

import styles from './Results.css'

export function Results({ enableRewardCalculator, page, jobs, totalJobs, isSuccess, isFetching, onPageChange, onResetClick, benefitPageRef }) {
  const { __ } = useTranslate()
  const maxPages = Math.ceil(totalJobs / JOBS_PER_PAGE)

  return (
    <div className={cx(styles.component, isFetching && styles.isLoading)}>
      {isSuccess && Boolean(jobs.length)
        ? <JobsList {...{ enableRewardCalculator, jobs, totalJobs, benefitPageRef }} />
        : (
          <div className={styles.noResults}>
            <NoResultsCard
              title={__`no-jobs-found`}
              dataX='clear-jobs-filters'
              {...{ onResetClick }}
            />
          </div>
        )
      }

      {totalJobs >= 1 && (
        <div data-style-context='light' className={styles.pagination}>
          <Pagination
            currentPage={page}
            {... { maxPages, onPageChange }}
          />
        </div>
      )}

      <JobAlert layoutClassName={styles.jobAlertLayout} />
    </div>
  )
}

function JobsList({ jobs, benefitPageRef, enableRewardCalculator }) {
  const language = useLanguage()
  const { __ } = useTranslate()

  return (
    <div className={styles.componentJobsList}>
      <ul className={styles.jobsList}>
        {jobs?.map(({ _source: job }, index) => {
          const mappedJob = mapJobData( job, { language })
          const { id, title, jobBranches, dateStart } = mappedJob

          const href = routeMap.app.job({
            language,
            jobId: id,
            jobTitle: makeSlug({ input: job.job_title, language: job.job_language })
          })

          const branches = jobBranches?.map(b => b?.[`label_${language}`] || b?.label_en)?.filter(Boolean) || []

          const labels = getJobLabels(mappedJob, { language })

          return (
            <React.Fragment key={id}>
              <li>
                <JobCard {...{ title, labels, href, dateStart, branches, id }} />
              </li>

              {(index === 4 || (index < 4 && index === jobs.length - 1)) && enableRewardCalculator && (
                <li>
                  <RewardCalculatorCtaJobsOverview
                    title={__`calculate-your-salary`}
                    text={__`calculator-jobs-explain-text`}
                    {...{ benefitPageRef }}
                  />
                </li>
              )}
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}
