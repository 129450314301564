import { ContainerLg } from '/features/buildingBlocks/Container'
import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useNormalizedJobFilterValues } from '/machinery/useNormalizedFilterValues'
import { useJobFiltersWithValues } from '/machinery/useFiltersWithValues'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useReportError } from '/machinery/ReportError'
import { FilterSearchField } from '/features/buildingBlocks/FilterSearchField'
import { useMediaQuery } from '@kaliber/use-media-query'
import { MobileFilters } from '/features/buildingBlocks/MobileFilters'
import { OverviewFilters } from '/features/buildingBlocks/OverviewFilters'
import { Results } from '/features/pageOnly/jobs/Results'
import { CountWithLabel } from '/features/buildingBlocks/CountWithLabel'
import { useScrollDepth } from '/machinery/useScrollDepth'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'
import { useTrackCurrentFiltersAndQuery, trackFilterInteraction } from '/analytics/user-interaction'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import mediaStyles from '/cssGlobal/media.css'
import styles from './JobsListAndFilter.css'

export function JobsListAndFilter({ initialJobs, totalJobsCount, filters, enableRewardCalculator, benefitPageRef }) {
  const { __ } = useTranslate()

  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const scrollRef = React.useRef(null)

  const [queryString, setQueryString] = useQueryString()

  const normalizedValues = useNormalizedJobFilterValues(queryString)

  const filtersAndValues = useJobFiltersWithValues({ filters, values: normalizedValues })

  const { jobs, totalJobs, isError, isSuccess, isFetching } = useFetchJobs({
    normalizedValues, initialJobs
  })

  useTrackCurrentFiltersAndQuery(filtersAndValues, totalJobs, 'multi_select', normalizedValues.q)

  const scrollTop = scrollRef?.current?.offsetTop
  const scrollStickyValue = useScrollDepth({ scrollTop })

  return (
    <section
      className={styles.component}
      ref={scrollRef}
      data-x='list-and-filter'
    >
      <ContainerLg layoutClassName={styles.containerLayout}>
        { isViewportMd && (
          <div className={styles.filterSearch}>
            <FilterSearchField
              layoutClassName={styles.searchFieldLayout}
              initialValue={normalizedValues.q}
              onSubmit={handleSearchChange}
            />
          </div>
        ) }

        <div className={styles.innerContainer} data-style-context='light'>
          { isViewportMd && (
            <div className={styles.filters}>
              <div className={styles.filterContent}>
                <h2 className={styles.filterHeading}>{ __`filters-title` }</h2>
                <div className={styles.jobFilters}>
                  <OverviewFilters
                    onReset={handleResetClick}
                    onFilterChange={handleFilterChange}
                    {...{ filtersAndValues }}
                  />
                </div>
              </div>
            </div>
          ) }

          { !isViewportMd && (
            <div className={cx(styles.mobileFilters, scrollStickyValue && styles.isSticky)}>
              <div className={cx(styles.innerMobileFilters, scrollStickyValue && styles.isSticky)}>
                <MobileFilters
                  onSearch={handleSearchChange}
                  searchQuery={normalizedValues.q}
                  totalCount={totalJobs}
                  {...{ handleFilterChange, handleResetClick, filtersAndValues }}
                />
              </div>
            </div>
          )}
          <div className={styles.main}>
            <div className={styles.counter}>
              <CountWithLabel
                count={totalJobsCount}
                total={totalJobs}
                totalFilteredText={__(totalJobsCount)`jobs-found`}
                noFilteredText={__(totalJobs)`jobs-found`}
              />
            </div>

            <Results
              page={normalizedValues.page}
              onPageChange={handleOnPageChange}
              onResetClick={handleResetClick}
              {...{ jobs, enableRewardCalculator, totalJobsCount, totalJobs, isError, isSuccess, isFetching, benefitPageRef }}
            />
          </div>
        </div>
      </ContainerLg>
    </section>
  )

  function handleResetClick() {
    setQueryString({})
  }

  function handleSearchChange(q) {
    trackFilterInteraction({ name: 'job_search_bar', value: q ? q.toString().toLowerCase() : null, inputType: 'job_search_bar' })
    pushToDataLayer({ metadata: { query: q ? q.toString().toLowerCase() : null } })
    handleFilterChange({ q })
  }

  function handleFilterChange(filterChanges) {
    setQueryString(x => ({ ...x, ...filterChanges, page: null }))
  }

  function handleOnPageChange(page) {
    window.scrollTo({ top: scrollTop, behavior: 'smooth' })
    setQueryString(x => ({ ...x, page }))
  }
}

function useFetchJobs({ normalizedValues, initialJobs }) {
  const reportError = useReportError()
  const language = useLanguage()

  const { data, isError, isSuccess, isFetching } = useQuery({
    queryKey: ['jobs', normalizedValues],
    initialData: initialJobs,
    queryFn: () => fetchJobs({ values: normalizedValues, language, reportError }),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    retry: 3,
  })

  const totalJobs = data?.hits?.total?.value ?? 0
  const jobs = data?.hits?.hits ?? []

  return { jobs, totalJobs, isError, isSuccess, isFetching }
}

async function fetchJobs({ values, language, reportError }) {
  const {
    page,
    q,
    country,
    job_branch,
    remote_type,
    contract_type,
    sub_expertise,
    salary_scale_min,
    salary_scale_max,
    job_type
  } = values

  try {
    return fetchWithResponseHandler(routeMap.api.v1.jobs(), {
      method: 'POST',
      body: JSON.stringify({
        filters: {
          q,
          country,
          remote_type,
          contract_type,
          job_branch,
          sub_expertise,
          job_type,
          salary_scale_min,
          salary_scale_max
        },
        language,
        page
      })
    })
  } catch (e) {
    reportError(e)
  }
}
